// import Vue from 'vue'
export default {
  namespaced: true,
  state: {
    tasks: null,
    activeId: null,
    activePlan: null,
    activeGroup: null
  },
  mutations: {
    setActiveId (state, id) {
      state.activeId = parseInt(id)
    },
    setData (state, data) {
      state.tasks = data
    },
    setActivePlan (state, plan) {
      state.activePlan = plan
    },
    setActiveGroup (state, group) {
      state.activeGroup = group
    },
  },
  getters: {
    tasks: (state) => {
      return state.tasks
    },
    activeId: (state) => {
      return state.activeId
    },
    activeTask: (state) => {
      if (!state.tasks || !state.activeId) {
        console.log(state.activeId)
        return null
      }
      return state.tasks.filter(e => {
        return e.id === state.activeId
      })[0]
    },
    activePlan: (state) => {
      return state.activePlan
    },
    activeGroup: (state) => {
      return state.activeGroup
    },
  },
  actions: {
    setActiveId ({ commit }, id) {
      console.log('setting id' + id)
      commit('setActiveId', id)
    },
    setActivePlan ({ commit }, plan) {
      commit('setActivePlan', plan)
    },
    setActiveGroup ({ commit }, group) {
      commit('setActiveGroup', group)
    },
    async fetch ({ commit, dispatch}) {
      console.log('task/fetch')
      const result = await dispatch(
        'http/get',
        { url: `/task/`},
        { root: true }
      )
      commit('setData', result.data)
      return result
    },
    async addTask ({ dispatch }, data) {
      try {
        console.log(data)
        const result = await dispatch(
          'http/post',
          { url: '/task/', data: data },
          { root: true }
        )
        return result.data
      } catch (err) {
        console.error(err)
        throw err
      }
    },
    async addGroup ({ dispatch }, {id, type, data, groupname}) {
      try {
        const result = await dispatch(
          'http/patch',
          { url: `/task/${id}/`, data: {data: data , type: type, groupname: groupname, action: 'materialgroup'}},
          { root: true }
        )
        return result.data
      } catch (err) {
        console.error(err)
        throw err
      }
    },
    async chageStatus ({ dispatch }, {id, status}) {
      try {
        const result = await dispatch(
          'http/patch',
          { url: `/task/${id}/`, data: {status: status ,action: 'status'}},
          { root: true }
        )
        return result.data
      } catch (err) {
        console.error(err)
        throw err
      }
    },
    async deleteGroup ({ dispatch }, {id, type, groupname}) {
      try {
        const result = await dispatch(
          'http/patch',
          { url: `/task/${id}/`, data: {type: type, groupname: groupname, action: 'del_materialgroup'}},
          { root: true }
        )
        return result.data
      } catch (err) {
        console.error(err)
        throw err
      }
    },
    async process ({ dispatch }, { id, framenum }) {
      await dispatch(
        'http/patch',
        { url: `/task/${id}/`, data: { framenum: framenum } },
        { root: true }
      )
      await dispatch('fetch')
    },
    async deleteBase ({ dispatch }, id) {
      await dispatch(
        'http/patch',
        { url: `/task/${id}/`, data: { type: "base" , action: "del"} },
        { root: true }
      )
      await dispatch('fetch')
    },
    async deletePillar ({ dispatch }, id) {
      await dispatch(
        'http/patch',
        { url: `/task/${id}/`, data: { type: "pillar" , action: "del"} },
        { root: true }
      )
      await dispatch('fetch')
    },
    async delete ({ dispatch }, id) {
      try {
        await dispatch(
          'http/delete',
          { url: `/task/${id}/` },
          { root: true }
        )
      } catch (err) {
        const { status } = err.response
        if (status === 404) {
          console.log('target task not found')
          //
        } else {
          throw err
        }
      }
      await dispatch('fetch')
    },
    async archive ({ dispatch }, id) {
      await dispatch(
        'http/post',
        { url: `/archive/${id}/` },
        { root: true }
      )
      await dispatch('fetch')
    }
  }
}
