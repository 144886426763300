import { createStore } from 'vuex'
import auth from '@/store/modules/auth'
import http from '@/store/modules/http'
import tasks from '@/store/modules/tasks'
// import pdfdatas from '@/store/modules/pdfdatas'
import history from '@/store/modules/history.js'
import construction from '@/store/modules/construction.js'
import createPersistedState from 'vuex-persistedstate' //ログイン認証の永続化
import canvas from '@/store/modules/canvas'
import angle from '@/store/modules/angle'
import mouse from '@/store/modules/mouse'
import materials from '@/store/modules/materials'
import materialsgroup from '@/store/modules/materialsgroup'
import materialsgrouppoint from '@/store/modules/materialsgrouppoint'

export default createStore({
  state: {
    count: 0
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    http,
    tasks,
    // pdfdatas,
    history,
    construction,
    canvas,
    mouse,
    angle,
    materials,
    materialsgroup,
    materialsgrouppoint,
  },
  plugins: [createPersistedState({
    key: 'taskuitoken',
    paths: ['auth'],
    storage: window.sessionStorage
  })]
})
