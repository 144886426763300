import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './assets/tailwind.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCalendar, faForward, faBackward, faPlay, faPause, faTrash, faExclamation, faCheck, faShoppingCart, faSearch, faFileImport, faLock, faRulerCombined, faEye, faPlus, faCloudUploadAlt, faRuler, faInfoCircle, faTimes, faFilePdf, faDownload, faFileUpload, faSyncAlt, faEdit, faAngleLeft, faAngleRight, faArrowLeft, faArrowRight, faMapPin, faDrawPolygon, faChartLine, faRobot, faViruses, faDotCircle, faClone, faPen, faSave } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons' // ブランドアイコンを使うにはこれを読み込む必要がある
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import RootData from '@/mixins/RootData.js'
// import BasePlugin from '@/plugins/Base.js'

library.add(faCalendar, faForward, faBackward, faPlay, faPause, faTrash, faExclamation, faCheck, faShoppingCart, faSearch, faFileImport, faLock, faRulerCombined, faEye, faPlus, faCloudUploadAlt, faRuler, faInfoCircle, faTimes, faFilePdf, faDownload, faFileUpload, faSyncAlt, faEdit, faAngleLeft, faAngleRight, faArrowRight, faArrowLeft, faMapPin, faDrawPolygon, faChartLine, faRobot, faViruses, faDotCircle, faClone, faPen, faSave)
library.add(faTwitter, faInstagram, faLinkedin)

createApp(App)
    .use({mixins: [RootData]})
    .use(router)
    .use(store)
    // .use(BasePlugin)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')