export default {
  namespaced: true,
  state: {
    history: []
  },
  mutations: {
    addHistory (state, data) {
      state.history.push(data)
      if (state.history.length > 3) {
        state.history.shift()
      }
    },
    popHistory (state) {
      if (!state.history.length) return
      state.history.splice(state.history.length - 1, 1)
    },
    clearHistory (state) {
      state.history = []
    }
  },
  getters: {
    history: (state) => {
      return state.history
    }
  },
  actions: {
    addHistory ({ commit }, data) {
      commit('addHistory', data)
    },
    popHistory ({ commit }) {
      commit('popHistory')
    },
    clearHistory ({ commit }) {
      commit('clearHistory')
    }
  }
}
