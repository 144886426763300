export default {
  namespaced: true,
  state: {
    mode: 'clean',
    dragging: false,
    cursor: 'default',
    startX: null,
    startY: null,
    lastMouX: 0,
    lastMouY: 0,
    lastImgX: 0,
    lastImgY: 0,
    prevMouX: 0,
    prevMouY: 0,
    absoMouX: 0,
    absoMouY: 0,
  },
  mutations: {
    setMode (state, data) {
      state.mode = data
    },
    setDragging (state, data) {
      state.dragging = data
    },
    setStartPosition (state, data) {
      state.startX = data.x
      state.startY = data.y
    },
    setCursor (state, data) {
      state.cursor = data
    },
    setLastMousePosition (state, data) {
      state.lastMouX = data.x
      state.lastMouY = data.y
    },
    setLastImgPosition (state, data) {
      state.lastImgX = data.x
      state.lastImgY = data.y
    },
    setPrevMousePosition (state, data) {
      state.prevMouX = data.x
      state.prevMouY = data.y
    },
    setAbsoMousePosition (state, data) {
      state.absoMouX = data.x
      state.absoMouY = data.y
    },
  },
  getters: {
    mode: (state) => {
      return state.mode
    },
    dragging: (state) => {
      return state.dragging
    },
    cursor: (state) => {
      return state.cursor
    },
    startX: (state) => {
      return state.startX
    },
    startY: (state) => {
      return state.startY
    },
    lastMouX: (state) => {
      return state.lastMouX
    },
    lastMouY: (state) => {
      return state.lastMouY
    },
    absoMouX: (state) => {
      return state.absoMouX
    },
    absoMouY: (state) => {
      return state.absoMouY
    },
    lastImgX: (state) => {
      return state.lastImgX
    },
    lastImgY: (state) => {
      return state.lastImgY
    },
    prevMouX: (state) => {
      return state.prevMouX
    },
    prevMouY: (state) => {
      return state.prevMouY
    }
  },
  actions: {
    setMode ({ commit }, data) {
      commit('setMode', data)
    },
    setDragging ({ commit }, data) {
      commit('setDragging', data)
    },
    setCursor ({ commit }, data) {
      commit('setCursor', data)
    },
    setStartPosition ({ commit }, data) {
      commit('setStartPosition', data)
    },
    setPrevMousePosition ({ commit }, data) {
      commit('setPrevMousePosition', data)
    },
    setLastMousePosition ({ commit }, data) {
      commit('setLastMousePosition', data)
    },
    setLastImgPosition ({ commit }, data) {
      commit('setLastImgPosition', data)
    },
    setAbsoMousePosition ({ commit }, data) {
      commit('setAbsoMousePosition', data)
    }
  }
}
