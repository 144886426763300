export default {
  namespaced: true,
  state: {
    context: null,
    svg:null,
    img: null,
    imgLoading: false,
    scale: null,
    prevscale: null,
    resized: false,
    height: 0,
    width: 0,
    tooltip: false,
    toolmsg: "",
    contextmenu: false,
    contextid: -1,
    grabmgp: null,
  },
  mutations: {
    setImage (state, data) {
      state.img = data
    },
    setImageLoading (state, data) {
      state.imgLoading = data
    },
    setScale (state, data) {
      state.scale = data
    },
    setPrevScale (state, data) {
      state.prevscale = data
    },
    setContext (state, data) {
      state.context = data
    },
    setContextMenu (state, data) {
      state.contextmenu = data
    },
    setContextId (state, data) {
      state.contextid = data
    },
    setSize (state, size) {
      // console.log(size)
      state.height = size.h-1 //ボタン分
      state.width = size.w-1
      state.resized = true
    },
    scaleUp (state) {
      state.scale += 0.01
      // state.scale = 1
    },
    scaleDown (state) {
      state.scale -= 0.01
      // state.scale = 1
    },
    setToolTip (state, data) {
      state.tooltip = data
    },
    setToolMsg (state, data) {
      state.toolmsg = data
    },
    setGrabMgp (state, data) {
      state.grabmgp = data
    }
  },
  getters: {
    image: (state) => state.img,
    imageLoading: state => state.imgLoading,
    scale: state => state.scale,
    prevscale: state => state.prevscale,
    context: (state) => state.context,
    contextmenu: (state) => state.contextmenu,
    contextid: (state) => state.contextid,
    tooltip: state => state.tooltip,
    toolmsg: state => state.toolmsg,
    grabmgp: state => state.grabmgp,
    size: (state) => {
      return { h: state.height, w: state.width }
    },
    resized: (state) => {
      return state.resized
    },
    canvasRatio: (state) => {
      return { x: state.width / state.img.width, y: state.height / state.img.height }
    }
  },
  actions: {
    setImage ({ commit }, data) {
      commit('setImage', data)
    },
    setImageLoading ({ commit }, data) {
      commit('setImageLoading', data)
    },
    setScale ({ commit }, data) {
      commit('setScale', data)
    },
    setPrevScale ({ commit }, data) {
      commit('setPrevScale', data)
    },
    setContext ({ commit }, data) {
      commit('setContext', data)
    },
    setContextMenu ({ commit }, data) {
      commit('setContextMenu', data)
    },
    setContextId ({ commit }, data) {
      commit('setContextId', data)
    },
    scaleUp ({ commit }) {
      commit('scaleUp')
    },
    scaleDown ({ commit }) {
      commit('scaleDown')
    },
    setToolTip ({ commit }, data) {
      commit('setToolTip', data)
    },
    setToolMsg ({ commit }, data) {
      commit('setToolMsg', data)
    },
    setGrabMgp ({ commit }, data) {
      commit('setGrabMgp', data)
    },
    async setSize ({ commit}, data) {
      if (data.x === 0) return
      commit('setSize', data)
      // await dispatch('results/resize', getters.canvasRatio, { root: true })
    }
  }
}
