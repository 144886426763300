import axios from 'axios'
import router from '../../router'

export default {
  namespaced: true,
  actions: {
    async request ({ rootState }, { method, url, data, contentType, responseType }) {
      const headers = {}
      if (contentType) {
        headers['Content-Type'] = contentType
      } else {
        headers['Content-Type'] = 'application/json'
      }
      if (rootState.auth.token) {
        headers.Authorization = `Token ${rootState.auth.token}`
      }
      const options = {
        method,
        url: `${process.env.VUE_APP_ENDPOINT}${url}`,
        headers,
        data,
        timeout: 50000
      }
      // console.log(options)
      if (responseType) options.responseType = responseType
      try {
        const result = await axios(options)
        return result
      } catch (err) {
        console.error(err)
        const { status } = err.response
        if (status === 401) {
          router.replace({ name: 'Login' })
        } else if (status >= 500) {
          // await dispatch('auth/destroy', null, { root: true })
          // router.push({ name: 'ServerError' })
        } else {
          throw err
        }
      }
    },
    async post ({ dispatch }, requests) {
      requests.method = 'post'
      return await dispatch('request', requests)
    },
    async delete ({ dispatch }, requests) {
      requests.method = 'delete'
      return await dispatch('request', requests)
    },
    async patch ({ dispatch }, requests) {
      requests.method = 'patch'
      return await dispatch('request', requests)
    },
    async get ({ dispatch }, requests) {
      requests.method = 'get'
      return await dispatch('request', requests)
    }
  }
}
