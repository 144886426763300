export default {
    namespaced: true,
    state:{
        materialsgroup: null,
        groupList: [],
    },
    mutations: {
        setData (state, data) {
            state.materialsgroup = data
        },
        setGroupList (state, data) {
            state.groupList = data
        }
    },
    getters: {
        materialsgroup: (state) =>{
            return state.materialsgroup
        },
        groupList: (state) =>{
            return state.groupList
        }
    },
    actions: {
        async fetch ({ commit, dispatch}, id) {
            // console.log('materials/fetch')
            const result = await dispatch(
              'http/get',
              { url: `/materialgroup/?task=${id}`},
              { root: true }
            )
            commit('setData', result.data)
            // console.log(this.state.materials)
            return result
        },
        setGroupList ({ commit }, data) {
            commit('setGroupList', data)
        },
    }
}