export default {
    namespaced: true,
    state: {
        main_view: true,
        file_view: false,
        material_view: false,
        baseace_view: false,
        pillar_view: false,
        base_view: false,
        point_view: false,
    },
    mutations: {
        onoffView(state, onoff) {
            state.main_view = onoff.main_view
            state.file_view = onoff.file_view
            state.material_view = onoff.material_view
            state.baseace_view = onoff.baseace_view
            state.pillar_view = onoff.pillar_view
            state.base_view = onoff.base_view
            state.point_view = onoff.point_view
        },
    },
    getters: {
        isMainView: (state) => {
            return state.main_view
        },
        isFileView: (state) => {
            return state.file_view
        },
        isMaterialView: (state) => {
            return state.material_view
        },
        isPillarView: (state) => {
            return state.pillar_view
        },
        isBaseView: (state) => {
            return state.base_view
        },
        isBaseAceView: (state) => {
            return state.baseace_view
        },
        isPointView: (state) => {
            return state.point_view
        },
    },
    actions: {
        onoffView({commit}, onoff) {
            commit('onoffView', onoff)
        },
    }
}