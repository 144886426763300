import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: 'Login'
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/user',
    name: 'User',
    component: () => import(/* webpackChunkName: "about" */ '../views/User.vue')
  },
  {
    path: '/construction',
    name: 'Construction',
    component: () => import(/* webpackChunkName: "about" */ '../views/Construction.vue')
  },
  {
    path: '/tasks',
    name: 'Tasks',
    component: () => import(/* webpackChunkName: "about" */ '../views/Tasks.vue')
  },
  {
    path: '/count',
    name: 'Count',
    component: () => import(/* webpackChunkName: "about" */ '../views/Count.vue')
  },
  {
    path: '/serverError',
    name: 'ServerError',
    component: () => import(/* webpackChunkName: "serverError" */ '../views/Error/500.vue')
  },
  {
    path: "/:pathMatch(.*)*",
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "" */ '../views/Error/404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
