export default {
  namespaced: true,
  state: {
    angleA: {
      from: null,
      to: null,
      deg: null
    },
    angleC: {
      from: null,
      to: null,
      deg: null
    }
  },
  mutations: {
    setAngleAFrom (state, data) {
      state.angleA.from = data
    },
    setAngleCFrom (state, data) {
      state.angleC.from = data
    },
    setAngleATo (state, data) {
      state.angleA.to = data
    },
    setAngleCTo (state, data) {
      state.angleC.to = data
    },
    setAngleADeg (state, data) {
      state.angleA.deg = data
    },
    setAngleCDeg (state, data) {
      state.angleC.deg = data
    },
    resetAngleA (state) {
      state.angleA = { from: null, to: null, deg: null }
    },
    resetAngleC (state) {
      state.angleC = { from: null, to: null, deg: null }
    }
  },
  getters: {
    angleA: (state) => {
      return state.angleA
    },
    angleC: (state) => {
      return state.angleC
    }
  },
  actions: {
    resetAngleA ({ commit }) {
      commit('resetAngleA')
    },
    resetAngleC ({ commit }) {
      commit('resetAngleC')
    },
    setAngleAFrom ({ commit }, data) {
      commit('setAngleAFrom', data)
    },
    setAngleCFrom ({ commit }, data) {
      commit('setAngleCFrom', data)
    },
    setAngleATo ({ commit }, data) {
      commit('setAngleATo', data)
    },
    setAngleCTo ({ commit }, data) {
      commit('setAngleCTo', data)
    },
    setAngleADeg ({ commit }, data) {
      commit('setAngleADeg', data)
    },
    setAngleCDeg ({ commit }, data) {
      commit('setAngleCDeg', data)
    }
  }
}
