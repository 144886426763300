
export default {
    namespaced: true,
    state:{
        materialsgrouppoint: null,
        activePointList: [],
    },
    mutations: {
        setData (state, data) {
            state.materialsgrouppoint = data
        },
        setActivePointList (state, data) {
            state.activePointList = data
        }
    },
    getters: {
        materialsgrouppoint: (state) =>{
            return state.materialsgrouppoint
        },
        activePointList: (state) =>{
            return state.activePointList
        }
    },
    actions: {
        async fetch ({ commit, dispatch}, id) {
            const result = await dispatch(
              'http/get',
              { url: `/materialgrouppoint/?task=${id}`},
              { root: true }
            )
            commit('setData', result.data)
            return result
        },
        async addPoint ({ dispatch }, {id, groupname, type, x, y}) {
            try {
                const result = await dispatch(
                'http/post',
                { url: '/materialgrouppoint/', data: {task: id, groupname: groupname, type:type, x:x, y:y} },
                { root: true }
                )
                return result.data
            } catch (err) {
                console.error(err)
                throw err
            }
        },
        async addExeDate ({ dispatch }, {id, d}) {
            try {
                
               var today = new Date(d)
               var year = today.getFullYear();
               var month = today.getMonth() + 1;
               var day = today.getDate();
        
              const result = await dispatch(
                'http/patch',
                { url: `/materialgrouppoint/${id}/`, data: {date:year + '/' + month + '/' + day, action:"exec_date"}},
                { root: true }
              )
              return result.data
            } catch (err) {
              console.error(err)
              throw err
            }
        },
        async changePoint ({ dispatch }, {id, x, y}) {
            try {
              const result = await dispatch(
                'http/patch',
                { url: `/materialgrouppoint/${id}/`, data: {x: x , y: y}},
                { root: true }
              )
              return result.data
            } catch (err) {
              console.error(err)
              throw err
            }
        },
        async changeState ({ dispatch }, {id, job, user}) {
            try {
            
              const result = await dispatch(
                'http/patch',
                { url: `/materialgrouppoint/${id}/`, data: {job: job, user: user, action: "change_state"}},
                { root: true }
              )
              return result.data
            } catch (err) {
              console.error(err)
              throw err
            }
        },
        async delete ({ dispatch }, id) {
            try {
                await dispatch(
                'http/delete',
                { url: `/materialgrouppoint/${id}/` },
                { root: true }
                )
            } catch (err) {
                const { status } = err.response
                if (status === 404) {
                console.log('target materialgrouppoint not found')
                //
                } else {
                throw err
                }
            }
        },
        setActivePointList ({ commit }, data) {
            commit('setActivePointList', data)
        },
    }
}