<template>
  <div class="absolute w-full h-full flex flex-col dark:text-white dark:bg-gray-600">
    <!-- <loading v-if="loading" /> -->
    <navbar />
    <router-view :key="$route.fullPath"/>
  </div>
</template>

<style>
#app {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* height: 100%!important;
  width: 100vw; */
  /* padding-top: 0px; */
  overflow: hidden;
}
</style>
<script>
import Base from '@/mixins/Base.js'
import Navbar from '@/components/Navbar.vue'
// import Loading from '@/components/Loading.vue'

export default {
  mixins: [Base],
  components: {
    Navbar,
    // Loading
  },
  data() {
    return {
      showMenu: false
    }
  },
}
</script>