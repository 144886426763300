import { mapGetters } from 'vuex'
export default {
  data () {
    return {
    }
  },
  computed: {
    serverHostName: function () {
      return process.env.VUE_APP_SERVERHOSTNAME
    },
    isAuthenticated: function () {
      return this.$store.getters['auth/isAuthenticated']
    },
    token: function () {
      return this.$store.getters['auth/token']
    },
    jobClass: function () {
      return this.$store.getters['auth/jobClass']
    },
    ...mapGetters({
      userInfo: 'auth/userInfo',
      users: 'auth/users',
      tasks: 'tasks/tasks',
      materials: 'materials/materials',
      materialsgroup: 'materialsgroup/materialsgroup',
      groupList: 'materialsgroup/groupList',
      materialsgrouppoint: 'materialsgrouppoint/materialsgrouppoint',
      activePointList: 'materialsgrouppoint/activePointList',
      activeTask: 'tasks/activeTask',
      activeTaskId: 'tasks/activeId',
      activePlan: 'tasks/activePlan',
      activeGroup: 'tasks/activeGroup',
      pdfDatas: 'pdfdatas/pdfDatas',
      mainView: 'construction/isMainView',
      fileView: 'construction/isFileView',
      materialView: 'construction/isMaterialView',
      baseView: 'construction/isBaseView',
      baseAceView: 'construction/isBaseAceView',
      pillarView: 'construction/isPillarView',
      pointView: 'construction/isPointView',
      img: 'canvas/image',
      imgLoading: 'canvas/imageLoading',
      scale: 'canvas/scale',
      prevscale: 'canvas/prevscale',
      context: 'canvas/context',
      contextmenu: 'canvas/contextmenu',
      contextid: 'canvas/contextid',
      canvasSize: 'canvas/size',
      resized: 'canvas/resized',
      canvasRatio: 'canvas/canvasRatio',
      tooltip: 'canvas/tooltip',
      toolmsg: 'canvas/toolmsg',
      grabmgp: 'canvas/grabmgp',
      mode: 'mouse/mode',
      activeLabel: 'results/activeLabel',
      activeResult: 'results/activeResult',
      dragging: 'mouse/dragging',
      cursor: 'mouse/cursor',
      startX: 'mouse/startX',
      startY: 'mouse/startY',
      lastMouX: 'mouse/lastMouX',
      lastMouY: 'mouse/lastMouY',
      lastImgX: 'mouse/lastImgX',
      lastImgY: 'mouse/lastImgY',
      prevMouX: 'mouse/prevMouX',
      prevMouY: 'mouse/prevMouY',
      absoMouX: 'mouse/absoMouX',
      absoMouY: 'mouse/absoMouY',
    })
  },
  methods: {
    getFullUrl: function (url) {
      if (!url) return ''
      const base = process.env.VUE_APP_SERVERHOSTNAME
      if (!url.includes('https://')) {
        return `${base}${url.replace(base, '')}`
      } else {
        return url
      }
    }
  }
}
