<template>
  <div class="d-inline mx-1">
    <span class="itoi">糸井商会</span>
    <!-- <b class="mes"> MES</b> -->
  </div>
</template>
<style scoped>
  .itoi, .mes {
    font-weight: 600;
    font-family: sans-serif;
  }
  .itoi {
    color: #333333;
    font-family: sans-serif;
  }
  .mes {
    color: #555555 ;
    font-family: sans-serif;
  }
</style>
