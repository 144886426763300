<template>
  <div class="flex items-center justify-between py-3 mb-2 shadow-md">
    <div  class="inline mx-1" href="#">
      <inline-logo class="text-white"></inline-logo>
    </div>
    <div class="sm:flex sm:items-center">
      <router-link  v-if="isAuthenticated" to="/tasks" class="mx-3">工事管理</router-link>
      <a v-if="isAuthenticated" v-bind:href="pdfPath" target=_blank>鉄筋形状</a>
      <router-link  v-if="isAuthenticated" to="/count" class="mx-3">鉄筋カウント</router-link>
      <div v-if="isAuthenticated">
        <router-link v-if="userInfo.is_superuser" to="/user" class="mx-3">ユーザー登録</router-link>
      </div>
      <a v-if="isAuthenticated" href="/" class="mx-3">ログアウト</a>
      <a
        v-else
        aria-current="page"
        class="router-link-active router-link-exact-active text-gray-800 dark:text-white text-sm font-semibold hover:text-blue mr-4"
        href="/">Sign in</a>
    </div>
    <!-- <img src="@/assets/logo.png" alt="Logo" class=""> -->
  </div>
</template>
<style scoped>

</style>
<script>
import Base from "@/mixins/Base.js";
import InlineLogo from './InlineLogo.vue'

export default {
  mixins: [Base],
  data (){
    return {
      pdfPath: 'tekkin_shape.pdf'
    }
  },
  components: {
    InlineLogo
  },
  computed: {
    
  },
  methods: {
    
  },
};
</script>
