export default {
    namespaced: true,
    state:{
        materials: null,
    },
    mutations: {
        setData (state, data) {
            state.materials = data
        }
    },
    getters: {
        materials: (state) =>{
            return state.materials
        }
    },
    actions: {
        async fetch ({ commit, dispatch}, id) {
            // console.log('materials/fetch')
            const result = await dispatch(
              'http/get',
              { url: `/material/?task=${id}`},
              { root: true }
            )
            commit('setData', result.data)
            // console.log(this.state.materials)
            return result
        },
    }
}