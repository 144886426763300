export default {
  namespaced: true,
  state: {
    token: '',
    userInfo: null,
  },
  mutations: {
    setToken (state, token) {
      state.token = token
    },
    setUserInfo (state, data) {
      state.userInfo = data
    },
    destroy (state) {
      state.token = ''
      state.userInfo = null
      state.users = null
    },
  },
  getters: {
    token: (state) => {
      return state.token
    },
    userInfo: (state) => {
      return state.userInfo
    },
    isAuthenticated: (state) => {
      return state.token !== ''
    },
    jobClass: (state) => {
      return state.userInfo.job_class
    },
  },
  actions: {
    async updateUserInfo ({ commit, getters, dispatch }) {
      const userId = getters.userInfo.id
      try {
        const result = await dispatch(
          'http/get',
          { url: `/users/${userId}/` },
          { root: true }
        )
        // console.log(result.data)
        commit('setUserInfo', result.data)
      } catch (err) {
        console.error(err)
        throw err
      }
    },
    async getToken ({ commit, dispatch }, data) {
      try {
        // console.log(data)
        const result = await dispatch(
          'http/post',
          { url: '/gettoken/', data: data },
          { root: true }
        )
        if (result.data.token) {
          commit('setToken', result.data.token)
          commit('setUserInfo', result.data.user)
        }
      } catch (err) {
        const { data } = err.response
        console.error(data)
        throw err
      }
    },
    async signUp ({dispatch }, data) {
      try {
        await dispatch(
          'http/post',
          { url: '/signup/', data: data },
          { root: true }
        )
        return true
      } catch (err) {
        const { data } = err.response
        console.error(data)
        throw err
      }
    },
    destroy ({ commit }) {
      commit('destroy')
    },
    async listUser ({dispatch}) {
      const result = await dispatch(
        'http/get',
        { url: `/user/`},
        { root: true }
      )
      return result
    },
    async addUser ({ dispatch }, data) {
      try {
        const response = await dispatch(
          'http/post',
          { url: '/user/', data: data },
          { root: true }
        )
        return response.data
      } catch (err) {
        console.error(err)
        throw err
      }
    },
    async deleteUser ({ dispatch }, id) {
      try {
        await dispatch(
          'http/delete',
          { url: `/user/${id}/` },
          { root: true }
        )
      } catch (err) {
        const { status } = err.response
        if (status === 404) {
          console.log('target user not found')
          //
        } else {
          throw err
        }
      }
    },
    async updateUser ({ dispatch }, {id, jobClass}) {
      try {
        console.log(jobClass)
        await dispatch(
          'http/patch',
          { url: `/user/${id}/`, data:{jobClass: jobClass} },
          { root: true }
        )
      } catch (err) {
        const { status } = err.response
        if (status === 404) {
          console.log('target user not found')
          //
        } else {
          throw err
        }
      }
    }
  }
}
